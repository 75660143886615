/*!

=========================================================
* Argon Design System React - v1.0.0 based on Argon Design System - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Core
@import "bootstrap/functions";
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "custom/mixins";
// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/grid";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/tooltip";
@import "bootstrap/utilities";
// Custom components
@import "custom/reboot";
@import "custom/global";
@import "custom/alerts";
@import "custom/buttons";
@import "custom/custom-forms";
@import "custom/icons";
@import "custom/nav";
@import "custom/progress";
@import "custom/type";
// Vendor
@import "custom/vendor/nouislider";
