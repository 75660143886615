.column-value {
  display: flex;
}

.column-slider {
  max-width: 300px;
}

.value {
  margin: auto;
}
