.option-name__container {
  display: flex;
}

.option-name__value {

  font-weight: bold;
}

@media (min-width: 576px) {
  .option-name__value {
    margin: auto 0 auto auto;
  }
}
