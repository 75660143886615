@import "src/application/assets/scss/bootstrap/_functions.scss";
@import "src/application/assets/scss/custom/variables";

main {
  min-height: calc(100vh - #{102px});
  padding-bottom: 1rem;
}

section {
  padding: 2rem 0 0 0;
}

h2 {
  line-height: 100px;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-image: linear-gradient(#{lighten($primary, 10%)}, $primary);
}

.password-box {
  max-width: 100%;
  height: 60px;
  margin: auto;
  border: 2px solid $gray-300;
  border-radius: 4px;
  text-align: center;
  background-color: white;
}

.password__value {
  font-size: 1rem;
  font-weight: 600;
  line-height: 60px;
}

.password__message {
  line-height: 28px;
}

@media (min-width: 576px) {
  h2 {
    font-size: 2.1875rem;
  }

  .password__value {
    font-size: 1.6275rem;
  }
}

@media (min-width: 768px) {
  .password-box {
    width: fit-content;
    min-width: 500px;
    padding: 0 2rem;
  }

  .password__message {
    line-height: 60px;
  }
}

#password {
  cursor: pointer;
}

#copy-password {
  position: absolute;
  top: -9999px;
}

.tip-message {
  margin-bottom: 0;
}

.alert {
  width: fit-content;
  margin: auto;
  margin-top: 1rem;

  i {
    margin-right: 10px;
    vertical-align: middle;
  }
}

.alert--show {
  opacity: 1;
}

.alert--hide {
  opacity: 0;
}

